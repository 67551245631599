import {Base} from './Base';
import {
    ApiResponse,
    GamesResponce,
    LoginResponse,
    NewBalanceResponse,
    NewGame, BuyBonus,
    SpinApiResponse,
    UserResponse,
} from './types';
import {
    IBalance,
    IUser,
    LoginForm,
    RegisterForm,
    SetBalance,
    UpdateForm,
} from './types/auth';
import TokenStorage from "../helpers/TokenStorage";
// import header from "../components/Header/Header";

export default class Auth extends Base {
    async login(values: LoginForm) {
        const response = await this.request.post<ApiResponse<LoginResponse>>(
            `public/login`,
            values
        );
        return response.data;
    }

    async register(values: RegisterForm) {
        const response = await this.request.post<ApiResponse<LoginResponse>>(
            `public/register`,
            values
        );
        return response.data;
    }

    async updateProfile(values: UpdateForm) {
        const response = await this.request.patch<ApiResponse<LoginResponse>>(
            `user/whoami`,
            values
        );
        return response.data;
    }

    async setBalance(values: SetBalance) {
        const response = await this.request.patch<NewBalanceResponse>(
            `user/setBalance`,
            values
        );
        return response.data;
    }

    async whoami() {
        const response = await this.request.get<UserResponse<IUser>>(
            'user/whoami'
        );

        return response.data.data;
    }

    async getUserGames() {
        const response = await this.request.get<GamesResponce>('user/getUserGames');

        return response.data;
    }

    async getUserBalance() {
        const response = await this.request.get<IBalance>('user/getUserBalance');

        return response.data.balance.value;
    }

    async newGame() {
        const token = TokenStorage.getToken()
        const response = await this.request.get<NewGame>('user/newGame',{headers: {Authorization: `Bearere ${token}`}});

        return response.data;
    }

    async spin(token: string, spinType: string, bet: number, anyWin?: boolean, freeSpins?: boolean, singleWin?: number, lossLimit?: number, refreshLimits = false) {
        const payload = {
            protocol: {
                index: 0
            },
            token: token,
            requestType: "slot",
            state: spinType,
            bet: {
                value: bet
            },
            limitReq:{
                anyWin: anyWin,
                freeSpins: freeSpins,
                singleWin: singleWin,
                lossLimit: lossLimit,
                refreshLoss: refreshLimits
            }

        }
        const response = await this.request.post<SpinApiResponse>(
            `user/spin`,
            payload
        );

        return response.data.response;
    }

    async debugReels(token: string, spinType: string, bet: number, positions: Array<Array<any>>, wilds: Array<any>) {
        const payload = {
            protocol: {
                index: 0
            },
            token: '',
            requestType: "spin",
            state: spinType,
            bet: {value: bet},
            positions: positions,
            wilds: wilds
        }
        const response = await this.request.post<SpinApiResponse>(
            `user/debugReels`,
            payload
        );

        return response.data.response;
    }

    async buyBonus(buyOption: number, cost: number, bet: number) {
        const payload = {
            buyOption: buyOption,
            cost: cost,
            bet: bet
        }
        const response = await this.request.post<SpinApiResponse>(
            `user/buyBonus`,
            payload
        );

        return response.data.response;
    }

    async buyDoubleBonus(token: string, spinType: string, bet: number, anyWin?: boolean, freeSpins?: boolean, singleWin?: number, lossLimit?: number, refreshLimit?: boolean
    ) {
        const payload = {
            protocol: {
                index: 0
            },
            token: token,
            requestType: "slot",
            state: spinType,
            bet: {
                value: bet
            },
            limitReq:{
                anyWin: anyWin,
                freeSpins: freeSpins,
                singleWin: singleWin,
                lossLimit: lossLimit,
                refreshLoss: refreshLimit
            }

        }
        const response = await this.request.post<SpinApiResponse>(
            `user/buyDoubleBonus`,
            payload
        );

        return response.data.response;
    }
}
