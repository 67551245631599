import { useCallback } from 'react';
import { LoginForm } from '../../../api/types/auth';
import { api } from '../../../App';
import TokenStorage from '../../../helpers/TokenStorage';
import { useActions } from '../../../hooks/useActions';
// import {readdir} from "fs/promises";
// import {RouteNames} from "../../../router";
// import {useNavigate} from "react-router-dom";

export const useAuth = () => {
	const { setAuth, setInfoBar, setError, setIsLoading, setTotalBet } = useActions();

	const handleLogin = useCallback(async (form: LoginForm) => {
		try {
			setIsLoading(true);
			const response = await api.auth.newGame();
			TokenStorage.setToken(form.token);
			if (response.isSuccess) {
				const body = {
					spinResult: 0,
					betValue: 1,
					balanceValue: response.response.account.value,
					totalBetValue: 0,
				};
				const betArray = response.gameSettings[4].value;
				setTotalBet(betArray);
				setInfoBar(body)
				setAuth(true);
				// window.location.reload();//
			}
			setIsLoading(false);
		} catch (error) {
			setError('Invalid token');
		}
	}, []);

	return {
		handleLogin,
	};
};
