import * as PIXI from 'pixi.js';
import { Application, Loader } from 'pixi.js';

import {AssetsLoader} from './assetLoader/AssetsLoader';
import {AssetsController} from './classes/AssetsController';
import {SlotMachine} from './classes/SlotMachine';
import {NewGameResponse} from '../../api/types';
import {api} from '../../App';
import {SoundController} from "./classes/SoundController";
import {AdaptiveComponent} from './classes/Adaptive';
import {InitialData} from "../../helpers/InitialData";
import { EventType, getEvent } from '../../GameEventBus';
import TokenStorage from "../../helpers/TokenStorage";

import {json} from "stream/consumers";
import {SetAuthAction} from "../../store/reducers/auth/types";

export let ratio = window.innerWidth / window.innerHeight;
let ratio1 = 1920 / 1080;
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR

export interface GameInitTypes {
    ref: any
    setAuth: any
    setInfoBar: any
    setTotalBet: any
}


export class PixiGame {
    isAssetsLoaded = false;
    app: Application;
    gameContainer: PIXI.Container;
    ref: any;
    isInitialized: boolean;
    assetsController?: AssetsController;
    adaptive?: AdaptiveComponent;
    slotMachine?: SlotMachine;
    initialData?: InitialData;
    gameInfo?: NewGameResponse;
    soundController: SoundController;
    type?: 'default' | 'debug' | 'bonusGame' | 'doubleBonusGame';

    constructor(type: 'default' | 'debug',) {
        this.isAssetsLoaded = false;
        this.app = new PIXI.Application({
            width: 1920,
            height: 1080,
            backgroundColor: 0x00,
            // antialias: true,
            resolution: window.innerWidth < 900 ? window.devicePixelRatio : 1,
            resizeTo: document.body
        });
        this.gameContainer = new PIXI.Container();
        this.ref = null;
        this.isInitialized = false;
        this.assetsController = undefined;
        this.adaptive = new AdaptiveComponent(this, this.app);
        this.slotMachine = undefined;
        this.soundController = new SoundController(this)
        this.initialData = undefined;
        this.type = type;

    }

    initialize = async ({setAuth, ref, setInfoBar, setTotalBet}: GameInitTypes) => {
        const gameInfo = await api.auth.newGame()

        const body = {
            spinResult: 0,
            betValue: 1,
            balanceValue: gameInfo.response.account.value,
            totalBetValue: 0,
        };

        setInfoBar(body)
        const betArray = gameInfo.gameSettings.find((setting) => setting.name === 'TOTAL_BET')!.value || [1, 2, 3, 4, 5]
        setTotalBet(betArray);

        setAuth(true)



        this.app.stage.sortableChildren = true
        if (this.isInitialized) return;
        this.ref = ref;

        this.isInitialized = true;

        this.gameContainer.width = 1920;
        this.gameContainer.height = 1080;
        this.gameContainer.sortableChildren = true;

        this.gameInfo = gameInfo.response;

        this.initialData = new InitialData();
        this.assetsController = new AssetsController(this, this.app);
        this.slotMachine = new SlotMachine(this);
        await this.soundController.initialize()

        await AssetsLoader(this.app, this.onAssetsLoaded);
        ref.current.appendChild(this.app.view);

        this.app.start();
    };

    onAssetsLoaded: Loader.OnCompleteSignal = async () => {
        if (this.isAssetsLoaded) return;
        await this.initializeScene()

        this.isAssetsLoaded = true;
            getEvent(EventType.GAME_ASSETS_LOADED).send(true)
    };

    initializeScene = async () => {
        await this?.assetsController?.setupAllComponents();
        this.app.stage.addChild(this.gameContainer);

        function resize(game: PixiGame) {
            if (window.innerWidth / window.innerHeight >= ratio) {
                var w = window.innerHeight * ratio1;
                var h = window.innerHeight;
            } else {
                var w = window.innerWidth;
                var h = window.innerWidth / ratio1;
            }

            game.app.view.width = window.innerWidth;
            game.app.view.height = window.innerHeight;

            game.gameContainer.scale.x = window.innerWidth / 1920;
            game.gameContainer.scale.y = window.innerWidth / 1920;

            if (window.innerHeight < 1080 * game.gameContainer.scale.y) {
                game.gameContainer.scale.x = window.innerHeight / 1080;
                game.gameContainer.scale.y = window.innerHeight / 1080;
                game.gameContainer.x =
                    (window.innerWidth - game.gameContainer.width) / 2;
            }

            game.assetsController?.changeVisibility(
                game.assetsController.infoBarMobile,
                false
            );
            game.assetsController?.changeVisibility(
                game.assetsController?.multiplierBarMobile,
                false
            );
            game.assetsController?.changeVisibility(
                game.assetsController.settingsButton?.button,
                false
            );
            game.assetsController?.changeVisibility(
                game.assetsController.betButton?.button,
                false
            );
            game.assetsController?.changeVisibility(
                game.assetsController.infoBar,
                false
            );
            game.assetsController?.changeVisibility(
                game.assetsController.infoBarMobilePortret,
                false
            );

            game.app.view.style.width = window.innerWidth + 'px';
            game.app.view.style.height = window.innerHeight + 'px';

            game?.assetsController!.changeBackgroundFSSize();
            game?.assetsController!.changeBackgroundSize();

            if (!game.assetsController) return;
            let adaptiveRatio = window.innerWidth / window.innerHeight;
            game.adaptive?.resizeModal();
            if (adaptiveRatio < 1) {
                game.adaptive?.resizeToPortrait()
            } else if (adaptiveRatio > 1.78 && window.innerWidth >= 1280) {
                game.adaptive?.resizeToDesktop1();
            } else if (adaptiveRatio < 1.78 && window.innerWidth < 1280) {;
                game.adaptive?.resizeToLandscapeMobile();
            } else if (adaptiveRatio > 1.78 && window.innerWidth < 1280) {
                game.adaptive?.resizeToLandscapeMobile();
            } else {
                game.adaptive?.resizeToDesktop2();
            }

            game.adaptive?.fixGameContainer();
            game.app.resize()
            return
        }



        resize(this);
        this.app.resize()

        window.addEventListener('resize', () => resize(this));

        window.addEventListener('orientationchange',() => resize(this));

        await this.slotMachine?.initialize();
        await this.assetsController?.winLine?.init()

    };
}
